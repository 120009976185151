.hero-light {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stats-light {
  position: absolute;
  z-index: -1;

  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.roadmap-light {
  position: absolute;
  width: 491px;
  height: 332px;
  left: calc(50% - 491px / 2 + 15%);
  top: 2981px;

  background: linear-gradient(90deg, #13fb73 0%, #0a8a86 100%);
  opacity: 0.25;
  filter: blur(200px);
  border-radius: 13px;
}

.features-light {
  position: absolute;
  width: 933px;
  height: 209px;
  left: calc(50% - 933px / 2 + 0.5px);
  top: 889px;

  background: linear-gradient(90deg, #13fb73 0%, #0a8a86 100%);
  opacity: 0.25;
  filter: blur(200px);
  border-radius: 13px;
}

.features-light-2 {
  position: absolute;
  width: 483.21px;
  height: 483.21px;
  left: calc(50% - 483.21px / 2 + 20%);
  top: 890px;

  background: #10f7ef;
  opacity: 0.15;
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.howtobuy-light {
  position: absolute;
  width: 406.84px;
  height: 406.84px;
  left: calc(50% - 406.84px / 2 - 15%);
  top: 2150px;

  background: #10f6ef;
  opacity: 0.15;
  filter: blur(200px);
}

.howtobuy-light-2 {
  position: absolute;
  width: 437px;
  height: 437px;
  left: calc(50% - 437px / 2 + 0.5px);
  top: 2200px;

  background: #10f6ef;
  opacity: 0.15;
  filter: blur(200px);
}

.header-bottom-bar {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);

  height: 2px;
  background: linear-gradient(90deg, black 0%, #c9fa49 50%, black 100%);

  width: 75%;
}

.hero-bullets-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.hero-bullets {
  color: white;
  transition: all 0.2s ease-out;
}

.hero-bullets-bg-perm {
  z-index: -1;
  background: #c9fa49;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 4px;
  transition: width 0.2s ease-out, height 0.3s ease-in-out;
}

.hero-bullets-bg {
  z-index: -10;
  background-color: #c2c2c2;
  opacity: 0.2;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 4px;
  transition: width 0.2s ease-out, height 0.3s ease-in-out;
}

/* Hover and active state for bullets */
.bullet-active .hero-bullets-bg,
.hero-bullets-wrapper:hover .hero-bullets-bg {
  width: 100%;
  height: 100%;
  background-color: #c2c2c2;
  opacity: 0.2;
}

.bullet-active .hero-bullets,
.hero-bullets-wrapper:hover .hero-bullets {
  color: #c9fa49;
  font-weight: 600;
}

/* Description styles */
.bullet-description {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, max-height 0.3s ease-in-out,
    padding 0.3s;
  color: white;
  opacity: 0;
}

/* Activate description on hover or in-view */
.bullet-active .bullet-description,
.hero-bullets-wrapper:hover .bullet-description {
  opacity: 1;
  max-height: 12rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: white;
}

.feature-card {
  position: relative; /* Necessary for absolute positioning of the ::before element */
  border-radius: 13px;
  overflow: hidden; /* To ensure the pseudo-element stays within the bounds of the card */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.feature-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c2c2c2;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  opacity: 0; /* Start with the background hidden */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
  transform: scale(
    1.2
  ); /* Start with a slightly larger scale for a zoom effect */
}

.feature-card:hover::before,
.feature-card.hover-active::before {
  opacity: 0.2; /* Fully reveal the background on hover */
  transform: scale(
    1
  ); /* Scale it back to normal size for a smooth appearance */
}

.card-heading,
.card-desc {
  transition: color 0.3s ease-out;
}

.feature-card:hover .card-desc,
.feature-card.hover-active .card-desc {
  color: white !important;
}

.tokenomics-card {
  width: 90%;
  background: #758c331a;
  backdrop-filter: blur(2px);
  border-radius: 16px;
}

.howtobuy-card {
  position: relative;
  background: rgba(7, 176, 171, 0.05);
  backdrop-filter: blur(2px);
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}

.htb-card-hover-active {
  svg {
    fill: #020913 !important;
  }

  img {
    opacity: 0 !important;
  }

  p {
    color: black;
  }
}

.htb-card-hover-active::before {
  opacity: 0.75 !important;
}

.howtobuy-card svg {
  transition: fill 0.3s ease-in-out;
  fill: #c9fa49;
}

.howtobuy-card img {
  transition: fill 0.3s ease-in-out;
  opacity: 1;
}

.howtobuy-card:hover svg {
  fill: #020913;
}

.howtobuy-card:hover img {
  opacity: 0;
}

.howtobuy-card::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #bbfc00;
  backdrop-filter: blur(4px);
  opacity: 0.05;
  transition: opacity 0.3s ease-in-out;
}

.howtobuy-card:hover::before {
  opacity: 0.75;
}

/* Ensure you import this CSS file if using module CSS */

.scrolling-icons {
  display: flex;
  align-items: center;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.scrolling-icons {
  position: relative;
}

.scrolling-icons::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 200%;
  height: 100%;
  background: inherit;
}

.fade-left {
  position: absolute;
  width: 15%;
  height: 100%;
  left: 0;
  top: 0;

  z-index: 99;

  background: linear-gradient(
    269.69deg,
    rgba(2, 9, 19, 0) -109.43%,
    #020913 106.01%
  );
}

.fade-right {
  position: absolute;
  width: 15%;
  height: 100%;
  right: 0;
  top: 0;

  z-index: 99;

  background: linear-gradient(
    269.69deg,
    rgba(2, 9, 19, 0) -109.43%,
    #020913 106.01%
  );
}

.roadmap-box {
  border: 1px solid #c9fa49;
}

.roadmap-bullets {
  color: white;
  transition: all 0.2s ease-out;
}

.roadmap-bullets-tip {
  background: #c9fa49;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
  height: 70%;
  width: 2px;

  transition: all 0.2s ease-out;
}

.phase-card {
  position: relative; /* For the absolute positioning of the ::before element */
  padding: 1.5rem;
  width: 100%;
  background: transparent;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden; /* To prevent the pseudo-element from overflowing */
  transition: all 0.3s ease-in-out;
}

.phase-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #c2c2c2;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  opacity: 0;
  transform: scale(1.2); /* Start with a zoomed-in effect */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 16px; /* Match the card’s border-radius */
}

.phase-card:hover::before,
.phase-card-active::before {
  opacity: 0.2; /* Fully reveal the background */
  transform: scale(1); /* Scale back to normal */
}

/* Phase title and bullets transitions */
.phase-title,
.roadmap-bullets,
.roadmap-bullets-tip {
  transition: all 0.3s ease-in-out;
}

/* Hover and active states */
.phase-card:hover .phase-title,
.phase-card-active .phase-title {
  color: #c9fa49;
}

.phase-card:hover .roadmap-bullets,
.phase-card-active .roadmap-bullets {
  color: white;
}

.phase-card:hover .roadmap-bullets-tip,
.phase-card-active .roadmap-bullets-tip {
  background: #c9fa49;
}
